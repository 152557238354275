import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Grid,
    Button,
    FormControl,
    TextField,
    Table,
    TableBody,
    TableRow,
    TableCell,
    InputAdornment,
    IconButton,
    Link,
    Fab,
    RadioGroup
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../theme';
import Footer from '../UI-Component/Footer';
import useSessionContext from '../sessions/useSessionContext';
import { useParams } from 'react-router-dom';
import actionAgainst from '../../images/action_against_hunger.png';
import iFRC from '../../images/Emblem_of_the_IFRC.png';
import sOSVillages from '../../images/sos_villages_d_enfants.png';
import mSF from '../../images/msf.png';
import oxfam from '../../images/oxfam.png';
import rSF from '../../images/rsf.png';
import wWF from '../../images/wwf.png';
import ClearIcon from '@material-ui/icons/Clear';
import usePaymentCalculator from '../utils/usePaymentCalculator';
import axios from 'axios';
import { errorHandling } from '../Functions/errorHandling';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f0f4f8'
    },
    checkoutContainer: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '100px',
        justifyContent: 'space-evenly',
        paddingBottom: '50px',
    },
    container: {
        marginTop: "40px",
        justifyContent: 'center'
    },
    elemContainer: {
        textAlign: 'left',

        padding: '15px',
    },
    logo: {
        height: 75,
    },
    heading: {
        borderBottom: '1px solid #eee'
    },
    firstCol: {
        border: '1px solid #eee',
        backgroundColor: "white",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        marginRight: 15,
        padding: 15,
    },
    secondCol: {
        border: '1px solid #eee',
        backgroundColor: "white",
        padding: 15,
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    actionAgainstHunger: {
        backgroundImage: `url(${actionAgainst})`,
    },
    emblemIFRC: {
        backgroundImage: `url(${iFRC})`,
    },
    oxfam: {
        backgroundImage: `url(${oxfam})`,
    },
    rsf: {
        backgroundImage: `url(${rSF})`,
    },
    sosVillage: {
        backgroundImage: `url(${sOSVillages})`,
    },
    wwf: {
        backgroundImage: `url(${wWF})`,
    },
    mSF: {
        backgroundImage: `url(${mSF})`,
    },
    cc_selector: {
        position: 'relative',
        '& input': {
            margin: '0',
            padding: '0',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
            position: 'relative',
            height: '100%',
            visibility: 'hidden',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            border: 0,
            '&:active ~ $drinkcard_cc': {
                opacity: '.9',
            },
            '&:checked ~ $drinkcard_cc': {

                WebkitFilter: 'none',
                MozFilter: 'none',
                filter: 'none'

            },
        },

    },

    drinkcard_cc: {
        cursor: 'pointer',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        display: 'inline-block',
        width: '100px',
        height: '70px',
        WebkitTransition: 'all 100ms ease-in',
        MozTransition: 'all 100ms ease-in',
        transition: 'all 100ms ease-in',
        WebkitFilter: 'brightness(1.8) grayscale(1) opacity(.7)',
        MozFilter: 'brightness(1.8) grayscale(1) opacity(.7)',
        filter: 'brightness(1.8) grayscale(1) opacity(.7)'
    },
    drinkcard_cc_hover: {
        WebkitFilter: 'brightness(1.2) grayscale(.5) opacity(.9)',
        MozFilter: 'brightness(1.2) grayscale(.5) opacity(.9)',
        filter: 'brightness(1.2) grayscale(.5) opacity(.9)'
    },
    a_visited: {
        color: '#888'
    },
    a: {
        color: '#444',
        textDecoration: 'none'
    },
    p: {
        marginBottom: '.3em'
    },
    '': {
        fontFamily: 'monospace'
    },
    cc_selector_2_label: {
        marginLeft: '7px'
    },
    span_cc: {
        color: '#6d84b4'
    },
    inlineFormControl: {
    },
    input: {
        marginRight: theme.spacing(2),
    },
    button: {
        minWidth: 'unset',
        height: 45,
        fontWeight: "bold",
        fontSize: 15
    },
    proceedBtn: {
        minWidth: 'unset',
        width: 'auto',
        height: 45,
        fontWeight: "bold",
        fontSize: 15
    },
    totalCell: {
        fontWeight: 'bold',
        fontSize: '1.5em',
        textTransform: 'uppercase'
    },
    iconAction:{
        marginRight: 5,
        boxShadow: 'none',
        fontWeight: 'bold',
        textTransform: 'none',
        border: '2px solid #e5087e',
        color: '#e5087e',
        backgroundColor: "white",
    },
    linkCancel: {
        color: '#e5087e',
        textTransform: 'none',
        fontSize: '15px',
        '&:hover': {
            textTransform: 'none',
            textDecoration: 'none'
            
        },
    },

}));

const Checkout = () => {
    const classes = useStyles(theme);
    const { user } = useSessionContext();
    const { product_id } = useParams();
    const [product, setProduct] = useState({});
    const [coupon, setCoupon] = useState(null);
    const [discountAmount, setDiscountAmount] = useState(null);
    const [discountPercent, setDiscountPercent] = useState(null);
    const [organisation, setOrganisation] = useState('');
    const [couponText, setCouponText] = useState('');
    const [payment, setPayment] = useState({ discount: 0, tps: 0, tvq: 0, total: 0 });
    const [loading, setLoading] = useState(false);


    const goHome = () =>{
        window.location.replace('/mon-compte')
        
    }

    if(!user || user.is_subscribed){
        goHome();
        return
    }

    const handleChange = (e) => {
        setOrganisation(e.target.value);
    };

    const handleClear = () => {
        setCouponText('');
        setDiscountAmount(null)
        setDiscountPercent(null)
        setCoupon(null);
    };

    const applyCoupon = () => {
        getCoupon()
            .then((data) => {
                if (typeof data?.id !== "undefined") {
                    setDiscountAmount(data.amount_off)
                    setDiscountPercent(data.percent_off)
                    setCoupon(data.id)
                } else {
                    setCouponText('')
                }
            });
    };

    const updateAmount = () => {
        const {
            calculatedTotal,
            calculateTVQ,
            calculatedTPS,
            calculatedDiscountedTVQ,
            calculatedDiscountedTPS,
            discountAmountNumber,
            calculatedTotalDiscounted
        } = usePaymentCalculator(product.price, discountAmount, discountPercent)
        if (discountAmount != null || discountPercent != null) {
            setPayment({
                discount: discountAmountNumber() || 0,
                tps: calculatedDiscountedTPS() || 0,
                tvq: calculatedDiscountedTVQ() || 0,
                total: calculatedTotalDiscounted() || 0,
            })
        } else {
            setPayment({
                discount: 0,
                tps: calculatedTPS() || 0,
                tvq: calculateTVQ() || 0,
                total: calculatedTotal() || 0,
            })
        }
    }

    const goToCheckout = () => {
        setLoading(true);
        axios.post(`/create_checkout_session/`+product_id, {
            coupon: coupon,
            supported_organization: organisation,
          })
          .then(async (response) => {
            if (response.status === 200 && response.data.error == false) {
                window.location.replace(response.data.url);
            }else{
                errorHandling(response.data.msg)
            }
            setLoading(false);
          })
          .catch((error) => {
            errorHandling(error);
            setLoading(false);
          });
          
    };

    const getProduct = () => fetch('/product/' + product_id).then((res) => res.json());
    const getCoupon = () => fetch('/coupon/' + couponText).then((res) => res.json());

    useEffect(() => {
        getProduct()
            .then((data) => {
                setProduct(data)
                if(data.is_enterprise && user.type_of_user!='Enterprise'){
                    goHome();
                    return
                }else if(data.is_partner && user.type_of_user!='Partner'){
                    goHome();
                    return
                }
            });
    }, []);

    useEffect(() => {
        updateAmount()
    }, [product, coupon]);


    return (
        <main className={classes.root}>
            <Container maxWidth="lg" className={classes.checkoutContainer}>
                <Box>
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={12} md={10} className={classes.elemContainer}>
                            <div className={classes.heading}>
                                <Typography variant="h1">
                                    Formulaire d'abonnement
                                </Typography>
                            </div>

                            <Grid container>
                                <Grid item md={6} className={classes.firstCol}>
                                    <Typography variant="h3" style={{
                                        fontWeight: '500'
                                    }}>Pour poursuivre sélectionnez l'une des organisations : </Typography>
                                    <Typography variant="body2" style={{
                                        marginBottom: '3vh'
                                    }}>5% de votre facture sera reversée à la cause de votre choix</Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="organisation" name="orgGroup">
                                            <Grid container spacing={2} style={{ marginBottom: '3vh' }}>
                                                <Grid item xs={4} s={3} className={classes.cc_selector}>
                                                    <input id="emblemIFRC" type="radio" name="orgGroup"
                                                        value="Croix-Rouge et Croissant-Rouge" onClick={handleChange} />
                                                    <label className={`${classes.drinkcard_cc} ${classes.emblemIFRC}`}
                                                        htmlFor="emblemIFRC"></label>
                                                </Grid>
                                                <Grid item xs={4} s={3} className={classes.cc_selector}>
                                                    <input id="actionAgainstHunger" type="radio" name="orgGroup"
                                                        value="Action Contre La Faim" onClick={handleChange} />
                                                    <label className={`${classes.drinkcard_cc} ${classes.actionAgainstHunger}`}
                                                        htmlFor="actionAgainstHunger">
                                                    </label>
                                                </Grid>
                                                <Grid item xs={4} s={3} className={classes.cc_selector}>
                                                    <input id="oxfam" type="radio" name="orgGroup" value="OXFAM" onClick={handleChange} />
                                                    <label className={`${classes.drinkcard_cc} ${classes.oxfam}`}
                                                        htmlFor="oxfam"></label>
                                                </Grid>
                                                <Grid item xs={4} s={3} className={classes.cc_selector}>
                                                    <input id="rsf" type="radio" name="orgGroup"
                                                        value="Reporters Sans Frontières" onClick={handleChange} />
                                                    <label className={`${classes.drinkcard_cc} ${classes.rsf}`} htmlFor="rsf"></label>
                                                </Grid>
                                                <Grid item xs={4} s={3} className={classes.cc_selector}>
                                                    <input id="sosVillage" type="radio" name="orgGroup" value="sosVillage"
                                                        onClick={handleChange} />
                                                    <label className={`${classes.drinkcard_cc} ${classes.sosVillage}`}
                                                        htmlFor="sosVillage"></label>
                                                </Grid>
                                                <Grid item xs={4} s={3} className={classes.cc_selector}>
                                                    <input id="wwf" type="radio" name="orgGroup"
                                                        value="Fond Mondial pour la Nature" onClick={handleChange} />
                                                    <label className={`${classes.drinkcard_cc} ${classes.wwf}`}
                                                        htmlFor="wwf"></label>
                                                </Grid>
                                                <Grid item xs={4} s={3} className={classes.cc_selector}>
                                                    <input id="mSF" type="radio" name="orgGroup"
                                                        value="Medecins sans frontieres" onClick={handleChange} />
                                                    <label className={`${classes.drinkcard_cc} ${classes.mSF}`}
                                                        htmlFor="mSF"></label>
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item md={5} className={classes.secondCol}>
                                    <Typography variant="h3" style={{
                                        fontWeight: '500'
                                    }}>Récapitulatif :</Typography>
                                    <div className={classes.details}>
                                        <Table className={classes.table} aria-label="simple table">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <Typography variant="body2" style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{product.name}</Typography>
                                                        <Typography variant="caption" style={{}}> ({product.duration + " mois"})</Typography>
                                                    </TableCell>
                                                    <TableCell align="right">{product.price} $</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <Grid container spacing={2} alignItems="center" className={classes.inlineFormControl}>
                                            <Grid item xs={8}>

                                                <TextField
                                                    className={classes.input}
                                                    label="COUPON"
                                                    fullWidth
                                                    variant="outlined"
                                                    value={couponText}
                                                    onChange={(e) => setCouponText(e.target.value)}
                                                    InputProps={{
                                                        endAdornment: (
                                                            coupon != null && (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="clear input"
                                                                        onClick={handleClear}
                                                                        edge="end"
                                                                    >
                                                                        <ClearIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button disabled={coupon != null} className={classes.button} onClick={applyCoupon}>
                                                    Appliquer
                                                </Button>
                                            </Grid>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>Sous-total</TableCell>
                                                        <TableCell align="right">{product.price} $</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>Réduction</TableCell>
                                                        <TableCell align="right">{payment.discount} $</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>TPS</TableCell>
                                                        <TableCell align="right">{payment.tps} $</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>TVQ</TableCell>
                                                        <TableCell align="right">{payment.tvq} $</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={classes.totalCell}>TOTAL</TableCell>
                                                        <TableCell align="right" className={classes.totalCell}>{payment.total} $</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            {organisation=='' && (
                                                    <Alert severity="warning">Pour finaliser, n’oubliez pas de choisir la cause que vous souhaitez soutenir!</Alert>
                                                )}
                                            <div style={{ textAlign: 'right', width: '100%' }}>
                                                <Fab
                                                    variant="extended"
                                                    size="large"
                                                    title="cancel"
                                                    className={classes.iconAction}
                                                >
                                                    <Link className={classes.linkCancel} href="/forfaits-entreprise" >
                                                        Annuler
                                                    </Link>
                                                </Fab>
                                                
                                                <Button disabled={organisation == '' || loading} startIcon={loading && <CircularProgress size={20} />} className={classes.proceedBtn} onClick={goToCheckout}>
                                                    Continuer
                                                </Button>
                                            </div>
                                        </Grid>
                                    </div>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer />
        </main>
    );
};

export default Checkout;
