import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import BaseForm from './Form/BaseForm';
import Blog from './Pages/Blog';
import Post from './Pages/Post';
import ConfirmationPage from './Pages/Connexion';
import UserAccount from './Pages/UserAccount';
import PolitiqueConfidentialite from './Pages/PolitiqueConfidentialite';
import Pricing from './Pages/Pricing';
import Confirmation from './Pages/Confirmation';
import ForgotPwdMessage from './Pages/ForgotPwdMessage';
import AccountRoute from './AccountRoute';
import PaymentReceived from './Pages/PaymentReceived';
import Faq from './Pages/Faq';
import Checkout from './Pages/Checkout';
import Reference from './Pages/Reference';
import Service from './Pages/Service'
import CorporateInfoForm from './Pages/CorporateInfoForm'


export default () => (
  <Switch>
    <Route path="/accueil" component={Home} />
    <Route
      path="/candidat"
      component={() => BaseForm({ userType: 'Candidate' })}
    />
    <Route
      path="/entreprise"
      component={() => BaseForm({ userType: 'Enterprise' })}
    />
    <Route
      path="/partenaire"
      component={() => BaseForm({ userType: 'Partner' })}
    />
    <Route path="/forfaits" component={() => Pricing({ type: 'Enterprise' })} />
    <Route path="/forfaits-entreprise" component={() => Pricing({ type: 'Enterprise' })} />
    <Route path="/forfaits-partenaire" component={() => Pricing({ type: 'Partner' })} />
    <Route path="/confirmation" component={Confirmation} />
    <Route path="/mot-de-passe-oublie" component={ForgotPwdMessage} />
    <Route path="/paiement-recu" component={PaymentReceived} />
    <Route path="/nouvelles" component={Blog} />
    <Route path="/article/:slug/:id" component={Post} />
    <Route path="/article/:slug" component={Post} />
    <Route path="/confirmation-page" component={ConfirmationPage} />
    <AccountRoute path="/mon-compte/:page" component={UserAccount} />
    <AccountRoute path="/mon-compte" component={UserAccount} />
    <Route path="/blog" component={Blog} />
    <Route
      path="/politique-de-confidentialite"
      component={PolitiqueConfidentialite}
    />
    <Route path="/paiement/:product_id" component={Checkout} />
    <Route path="/faq-entreprise" component={() => Faq({ type: 'Enterprise' })} />
    <Route path="/faq-candidat" component={() => Faq({ type: 'Candidate' })} />
    <Route path="/faq-partenaire" component={() => Faq({ type: 'Partner' })} />
    <Route path="/faq-parrainage" component={() => Faq({ type: 'Referrer' })} />
    <Route path="/parrainage-candidat" component={() => Reference({ type: 'Candidate' })} />
    <Route path="/parrainage-entreprise" component={() => Reference({ type: 'Enterprise' })} />
    <Route path="/service/recrutement-international" component={() => Service({ page: 'recrutement-international' })} />
    <Route path="/service/immigrant-investisseur" component={() => Service({ page: 'immigrant-investisseur' })} />
    <Route path="/service/pret-de-personnel" component={() => Service({ page: 'pret-de-personnel' })} />
    <Route path="/service/sous-traitance" component={() => Service({ page: 'sous-traitance' })} />
    <Route path="/programme-corporatif/formulaire" component={CorporateInfoForm} />
    <Redirect from="/" to="/accueil" />
  </Switch>
);
